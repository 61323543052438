import { inject, Injectable } from '@angular/core';
import { injectMutation, injectQuery, injectQueryClient, keepPreviousData } from '@ngneat/query';
import { PaginationResponseInterface, ShippingAddress } from '@lobos/common-v3';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '../error/error-handler.service';

@Injectable({ providedIn: 'root' })
export class AddressService {
  _http = inject(HttpClient);
  _query = injectQuery();
  _mutation = injectMutation();
  _client = injectQueryClient();
  errorHandler = inject(ErrorHandlerService);

  getAddresses(page: number, size = 12, sort: string | null = null) {
    const params = new URLSearchParams({ intPageIndex: page.toString(), intPageSize: size.toString() });
    if (sort) {
      params.set('sOrderBy', sort);
    }
    return this._query({
      queryKey: ['shipping-addresses', page] as const,
      queryFn: () => {
        return this._http.get<PaginationResponseInterface<ShippingAddress>>(`/api/shipping-address?${params.toString()}`);
      },
      placeholderData: keepPreviousData,
    });
  }

  addAddress() {
    return this._mutation({
      onSuccess: () => {
        return this._client.invalidateQueries({ queryKey: ['shipping-addresses'] });
      },
      onError: () => {
        this.errorHandler.handleError({
          label: 'addresses.add-error',
          translate: true,
          translateScope: 'profile',
          name: 'Address add error',
          message: 'Something went wrong while adding the address',
          display: true,
        });
      },
      mutationFn: (address: Partial<ShippingAddress>) => this._http.post<ShippingAddress>(`/api/shipping-address`, address),
    });
  }

  updateAddress() {
    return this._mutation({
      onSuccess: () => {
        return this._client.invalidateQueries({ queryKey: ['shipping-addresses'] });
      },
      onError: () => {
        this.errorHandler.handleError({
          label: 'addresses.update-error',
          translate: true,
          translateScope: 'profile',
          name: 'Address update error',
          message: 'Something went wrong while updating the address',
          display: true,
        });
      },
      mutationFn: (address: ShippingAddress) =>
        this._http.put<ShippingAddress>(`/api/shipping-address/${address.lngAddressID}`, {
          ...address,
        }),
    });
  }

  deleteAddress() {
    return this._mutation({
      onSuccess: () => {
        return this._client.invalidateQueries({ queryKey: ['shipping-addresses'] });
      },
      onError: () => {
        this.errorHandler.handleError({
          label: 'addresses.delete-error',
          translate: true,
          translateScope: 'profile',
          name: 'Address delete error',
          message: 'Something went wrong while deleting the address',
          display: true,
        });
      },
      mutationFn: (address: ShippingAddress) => this._http.delete<ShippingAddress>(`/api/shipping-address/${address.lngAddressID}`),
    });
  }
}
